import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Activity from '../views/Activity.vue'
import Account from '../views/Account.vue'
import Community from '../views/community/Community.vue'
import Dividends from '../views/Dividends.vue'
import Markets from '../views/Markets.vue'
import Feedback from '../views/Feedback.vue'
import Company from '../views/Company.vue'
import Allocation from '../views/advanced/Allocation.vue'
import Swings from '../views/advanced/Swings.vue'
import Screener from '../views/Screener.vue'
import ProfessionalSetup from '../views/ProfessionalSetup.vue'
import Login from '../views/Login.vue'

import Portfolio from '../views/public/Portfolio.vue'
import EuropeanDividendStocksPortfolio from '../views/public/EuropeanDividendStocksPortfolio.vue'
import IntroducingZune from '../views/public/IntroducingZune.vue'
import Stock from '../views/public/Stock.vue'
import Netherlands from '../views/public/Netherlands.vue'
import Brazil from '../views/public/Brazil.vue'
import NetWorthCalculator from '../views/public/NetWorthCalculator.vue'
import Privacy from '../views/public/Privacy.vue'
import TermsOfUse from '../views/public/TermsOfUse.vue'

import { authGuard } from "../auth/authGuard";
import { signupGuard } from "../auth/signupGuard";

Vue.use(VueRouter)

const routes = [
  {
    path: '/dashboard',
    name: 'Home',
    component: Home,
    beforeEnter: authGuard
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: authGuard
  },
  {
    path: '/signup',
    name: 'Signup',
    beforeEnter: signupGuard
  },
  {
    path: '/dividends',
    name: 'Dividends',
    component: Dividends,
    beforeEnter: authGuard
  },
  {
    path: '/markets',
    name: 'Markets',
    component: Markets,
    beforeEnter: authGuard
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: Feedback,
    beforeEnter: authGuard
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: Feedback,
    beforeEnter: authGuard
  },
  {
    path: '/company/:symbol',
    name: 'Company',
    component: Company,
    beforeEnter: authGuard
  },
  {
    path: '/Swings',
    name: 'Swings',
    component: Swings,
    beforeEnter: authGuard
  },
  {
    path: '/Allocation',
    name: 'Allocation',
    component: Allocation,
    beforeEnter: authGuard
  },
  {
    path: '/premium',
    name: 'ProfessionalSetup',
    component: ProfessionalSetup
  },
  {
    path: '/portfolio/:id',
    name: 'Portfolio',
    component: Portfolio
  },
  {
    path: '/Stocks/Netherlands',
    name: 'Netherlands',
    component: Netherlands
  },{
    path: '/Stocks/Brazil',
    name: 'Brazil',
    component: Brazil
  },
  {
    path: '/screener',
    name: 'Screener',
    component: Screener
  },
  {
    path: '/net-worth-calculator',
    name: 'NetWorthCalculator',
    component: NetWorthCalculator
  },
  {
    path: '/stock/:symbol',
    name: 'Stock',
    component: Stock
  },
  {
    path: '/activity/:portfolio',
    name: 'Activity',
    component: Activity
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy
  },
  {
    path: '/terms',
    name: 'Terms',
    component: TermsOfUse
  },
  {
    path: '/account',
    name: 'Account',
    component: Account
  },
  {
    path: '/community',
    name: 'Community',
    component: Community,
    beforeEnter: authGuard
  },
  {
    path: '/demo',
    name: 'PortfolioDemo',
    component: Portfolio
  },
  {
    path: '/european-dividend-stocks',
    name: 'EuropeanDividendStocks',
    component: EuropeanDividendStocksPortfolio
  },
  {
    path: '/intro',
    name: 'IntroducingZune',
    component: IntroducingZune
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    //beforeEnter: authGuard
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
})

export default router
