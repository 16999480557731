<template>
  <section class="section">
    <div class="columns">
      <div class="column">
        <nav class="navbar" role="navigation" aria-label="main navigation">
          <div class="navbar-brand">
            <a class="navbar-item" href="https://zune.money">
              <strong>ZuneMoney</strong><span class="is-size-7">Beta</span></a>
            <!-- <div class="navbar-burger burger"><span></span><span></span><span></span></div> -->
            <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false"
              data-target="navbarBasicExample">
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>
          <div id="navbarBasicExample" class="navbar-menu">
            <div class="navbar-start">
              <a class="navbar-item is-hidden">
                Community
              </a>
              <a class="navbar-item is-hidden">
                Blog
              </a>
              <a class="navbar-item" href="https://zune.money/#dividend-tracker">
                Dividend Tracking
              </a>
              <a class="navbar-item" href="https://zune.money/#portfolio-performance">
                Performance Comparision
              </a>
              <a class="navbar-item" href="https://app.zune.money/demo">
                Demo
              </a>
              <div class="navbar-item has-dropdown is-hoverable is-hidden">
                <a class="navbar-link">
                  Features
                </a>
                <div class="navbar-dropdown">
                  <a class="navbar-item">
                    Dividend Tracking
                  </a>
                  <a class="navbar-item">
                    Performance Comparision
                  </a>
                  <!-- <a class="navbar-item is-hidden">
                                    Dividend Alerts
                                </a> -->
                  <hr class="navbar-divider">
                  <a class="navbar-item">
                    Demo
                  </a>
                </div>
              </div>
            </div>
            <div class="navbar-end">
              <div class="navbar-item">
                <div class="buttons">
                  <a class="button is-primary" href="https://app.zune.money/signup">
                    <strong>Sign up</strong>
                  </a>
                  <a class="button is-light" href="https://app.zune.money">
                    Log in
                  </a>
                </div>
              </div>
            </div>
          </div>
        </nav>

        <section class="hero bd-hero is-warning mt-3">
          <div class="hero-body">
            <div class="bd-hero-body">
              <div class="bd-hero-heading">
                <h2 class="heading is-size-5">Track {{ companyFundamentals.general.code }} Stock Price and Dividends</h2>
                <p>Track {{ companyFundamentals.general.code }} stock price and performance with ZuneMoney's 
                  portfolio tracking tools. Discover {{ companyInfo && companyInfo.name || companyFundamentals.general.name }}'s dividend 
                  yield <span v-if="companyFundamentals.general.sector">, {{ companyFundamentals.general.sector.toLowerCase() }} sector trends</span>, 
                    and detailed analytics to manage your {{ companyInfo && companyInfo.name || companyFundamentals.general.name }} shares. 
                    Stay informed with ZuneMoney's insights for smarter stock investments.</p>
              </div>
            </div>
          </div>


          <!-- Hero footer: will stick at the bottom -->
          <div class="hero-foot">
            <nav class="tabs is-boxed is-pulled-right">
              <div class="container">
                <ul>
                  <li> <a href="https://app.zune.money/signup" class="nav-link"><button
                        class="button is-success has-text-weight-bold">Try
                        Now!🚀</button></a></li>
                </ul>
              </div>
            </nav>
          </div>
        </section>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="level is-mobile">
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">{{ companyFundamentals.general.exchange }}</p>
              <h1 class="title is-size-5-mobile">
                {{ companyFundamentals.general.code }} - {{ companyInfo && companyInfo.name || companyFundamentals.general.name }}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="level is-mobile">
          <div class="level-item has-text-centered">
            <div>
              <h2 class="heading">Symbol</h2>
              <p class="title is-size-5-mobile">
                {{ companyFundamentals.general.code }}
              </p>
            </div>
          </div>
          <div class="level-item has-text-centered is-hidden-mobile">
            <div>
              <h2 class="heading">Price</h2>
              <p class="title is-size-5-mobile">
                <span class="is-size-6">{{ companyInfo.currentPriceCurrency }}</span>
                <span class="is-white">{{ toLocaleNumber(companyLastClose) }}</span>
              </p>
            </div>
          </div>
          <div class="level-item has-text-centered" v-if="companyInfo.stat">
            <div>
              <h2 class="heading">PE</h2>
              <p class="title is-size-5-mobile">
                {{
                  companyInfo.stat.peRatio
                    ? toLocaleNumber(companyInfo.stat.peRatio)
                    : "-"
                }}
              </p>
            </div>
          </div>
          <div class="level-item has-text-centered is-hidden-mobile" v-if="companyInfo.stat">
            <div>
              <!-- We only calculate the dividends of your current holdings -->
              <h2 class="heading">Div. Yield(%)</h2>
              <p class="title is-size-5-mobile">
                {{companyInfo.stat && 
                  companyInfo.stat.dividendYield
                    ? toLocaleNumber(companyInfo.stat.dividendYield * 100) + "%"
                    : "-"
                }}
              </p>
            </div>
          </div>
          <!-- <div class="level-item has-text-centered is-hidden-mobile" v-if="companyInfo.extendedStats">
            <div>
              We only calculate the dividends of your current holdings
              <h2 class="heading">Payout Ratio(%)</h2>
              <p class="title is-size-5-mobile">
                {{
                  companyInfo.extendedStats.payoutRatio
                  ? toLocaleNumber(
                    companyInfo.extendedStats.payoutRatio * 100
                  ) + "%"
                  : "-"
                }}
              </p>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="columns is-hidden-tablet">
      <div class="column">
        <div class="level is-mobile">
          <div class="level-item has-text-centered">
            <div>
              <h2 class="heading">Price</h2>
              <p class="title is-size-5-mobile">
                <span class="is-white">{{
                  toLocaleNumber(companyLastClose)
                }}</span>
              </p>
            </div>
          </div>
          <div class="level-item has-text-centered" v-if="companyInfo.stat">
            <div>
              <!-- We only calculate the dividends of your current holdings -->
              <h2 class="heading">Div. Yield(%)</h2>
              <p class="title is-size-5-mobile">
                {{companyInfo.stat && 
                  companyInfo.stat.dividendYield
                    ? toLocaleNumber(companyInfo.stat.dividendYield * 100) + "%"
                    : "-"
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- COMPANY SUMMARY -->
    <div class="columns">
      <div class="column is-one-third has-text-centered">
        <img v-if="companyFundamentals.general.webURL"
          :src="'https://logo.clearbit.com/' + companyFundamentals.general.webURL"
          :alt="companyInfo && companyInfo.name || companyFundamentals.general.name">
        <div class="level is-mobile">
          <div class="level-item has-text-centered">
            <div class="flex column">
              <p class="heading"></p>
              <p class="title is-size-5-mobile"></p>
              <div class="body">
                <div class="card events-card">
                  <header class="card-header">
                    <p class="card-header-title">Summary</p>
                  </header>
                  <div class="card-table has-text-left">
                    <div class="content">
                      <table class="table is-fullwidth is-striped">
                        <tbody>
                          <tr>
                            <td>
                              <span>Sector</span>
                            </td>
                            <td class="level-right">
                              {{ companyFundamentals.general.sector }}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span>Industry</span>
                            </td>
                            <td class="level-right">
                              {{ companyFundamentals.general.industry }}
                            </td>
                          </tr>
                          <!-- <tr>
                            <td>
                              <span>EPS (TTM)</span>
                            </td>
                            <td class="level-right">
                              {{
                                companyInfo.stat.ttmEPS
                                ? toLocaleNumber(companyInfo.stat.ttmEPS)
                                : "-"
                              }}
                            </td>
                          </tr> -->
                          <tr>
                            <td>
                              <span>Employees</span>
                            </td>
                            <td class="level-right">
                              {{ companyFundamentals.general.fullTimeEmployees || "-" }}
                            </td>
                          </tr>
                          <tr v-if="companyFundamentals.general.webURL">
                            <td>
                              <span>Website</span>
                            </td>
                            <td class="level-right">
                              <a :href="companyFundamentals.general.webURL" target="_blank">{{
                                companyFundamentals.general.webURL }}</a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <p class="footer">{{ companyInfo.currentPrice }}</p> -->
            </div>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="level">
          <div class="level-left"></div>
          <div class="level-right is-pulled-right">
            <div class="buttons">
              <button type="button" class="button is-white" :class="{ 'is-light': selectedPeriod == 'week' }"
                @click="period('week')">
                WEEK
              </button>
              <button type="button" class="button is-white" :class="{ 'is-light': selectedPeriod == 'month' }"
                @click="period('month')">
                MONTH
              </button>
              <button type="button" class="button is-white" :class="{ 'is-light': selectedPeriod == '12-months' }"
                @click="period('12-months')">
                12-MONTHS
              </button>
              <button type="button" class="button is-white" :class="{ 'is-light': selectedPeriod == '5-years' }"
                @click="period('5-years')">
                5-YEARS
              </button>
            </div>
          </div>
        </div>
        <!-- <line-chart v-if="false" :chartData="this.priceChart.data" :options="this.priceChart.options" ref="priceChart"></line-chart> -->
      </div>
    </div>
    <!-- / COMPANY SUMMARY -->

    <!-- ABOUT -->
    <div class="columns" v-if="this.companyFundamentals.general.description">
      <div class="column">
        <h2 class="title">About {{ companyInfo && companyInfo.name || companyFundamentals.general.name }}</h2>
        <p>{{ this.companyFundamentals.general.description }}</p>
      </div>
    </div>
    <!-- / ABOUT -->

    <!-- DIVIDEND INSIGHTS -->
    <div class="columns" v-if="companyDividends.length > 0">
      <div class="column">
        <h2 class="title">{{ companyFundamentals.general.code }} - Dividend History</h2>
        <div class="columns" v-if="companyDividends.length > 0">
          <dividends-chart :chartData="this.dividendsChart.data" :options="this.dividendsChart.options"
            ref="dividendsChart" class="column"></dividends-chart>
        </div>

        <h3 class="title">{{ companyFundamentals.general.code }} - Dividend History Payments</h3>
        <div class="dividends-section">
          <table class="table is-fullwidth is-striped is-hoverable">
            <thead>
              <tr>
                <th class="is-hidden-mobile">Declared</th>
                <th class="is-hidden-mobile">Record</th>
                <th>Payable</th>
                <th>Amount</th>
                <th class="is-hidden-mobile">Frequency</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(dividend, i) in companyDividends" :key="i">
                <td class="is-hidden-mobile">
                  {{ dividend.declarationDate || "-" }}
                </td>
                <td class="is-hidden-mobile">
                  {{ dividend.recordDate || "-" }}
                </td>
                <td>
                  {{ dividend.paymentDate }}
                </td>
                <td>
                  <span class="amount">
                    {{ dividend.currency }} {{ toLocaleNumber(dividend.value) }}
                  </span>
                </td>
                <td class="is-hidden-mobile">
                  {{ dividend.period || "-" }}
                </td>
              </tr>
            </tbody>
          </table>
          <!-- Mobile Card View -->
          <div class="dividend-cards">
            <div class="card dividend-card" v-for="(dividend, i) in companyDividends" :key="i">
              <div class="card-content">
                <p class="payable-date">
                  <strong>Payable:</strong> {{ dividend.paymentDate }}
                </p>
                <p class="amount">
                  <strong>Amount:</strong> {{ dividend.currency }} {{ toLocaleNumber(dividend.value) }}
                </p>
                <p class="is-hidden-tablet">
                  <strong>Declared:</strong> {{ dividend.declarationDate || "-" }}
                </p>
                <p class="is-hidden-tablet">
                  <strong>Record:</strong> {{ dividend.recordDate || "-" }}
                </p>
                <p class="is-hidden-tablet">
                  <strong>Frequency:</strong> {{ dividend.period || "-" }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="columns" v-if="false && companyInfo.dividends && companyInfo.dividends.length > 0">
      <div class="column">
        <h2 class="title">{{ companyFundamentals.general.code }} - Dividend Insights</h2>
        <div class="card events-card">
          <div class="card-table has-text-left">
            <div class="content">
              <table class="table is-fullwidth is-striped">
                <tbody>
                  <tr>
                    <td>
                      <span>Ex Date</span>
                    </td>
                    <td class="level-right">
                      {{ companyInfo.stat.exDividendDate }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Next Dividend Date</span>
                    </td>
                    <td class="level-right">
                      {{ companyInfo.stat.nextDividendDate || "N/A" }}
                    </td>
                  </tr>
                  <!-- <tr>
                    <td>
                      <span>Payout Ratio</span>
                    </td>
                    <td class="level-right">
                      {{
                        toLocaleNumber(
                          companyInfo.extendedStats.payoutRatio * 100
                        )
                      }}%
                    </td>
                  </tr> -->
                  <!-- <tr>
                    <td>
                      <span>5 Year Growth Rate</span>
                    </td>
                    <td class="level-right"></td>
                  </tr>
                  <tr>
                    <td>
                      <span>Dividend Growth</span>
                    </td>
                    <td class="level-right"></td>
                  </tr> -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- / DIVIDEND INSIGHTS -->

    <!-- <stock-faq v-if="companyInfo.faq" :faq="companyInfo.faq"></stock-faq> -->

    <!-- <div class="columns" v-if="this.companyInfo.faq">

      <div class="column" v-for="faq in this.companyInfo.faq" :key="faq.id">
        <h2>{{ faq.question }}</h2>
        <p>{{ faq.answer }}</p>
      </div>
    </div> -->

    <section class="hero bd-hero is-info mt-3">
      <div class="hero-body">
        <div class="bd-hero-body">
          <div class="bd-hero-heading">
            <h2 class="heading is-size-5">Track {{ companyFundamentals.general.code }} and More with ZuneMoney</h2>
            <p>Join ZuneMoney to monitor {{ companyInfo && companyInfo.name || companyFundamentals.general.name }} stock price, dividend yield, and portfolio performance. Explore stocks<span v-if="companyFundamentals.general.sector"> in the {{ companyFundamentals.general.sector.toLowerCase() }} sector</span> and optimize your investments with our detailed analytics—start today!</p>
          </div>
        </div>
      </div>
      <div class="hero-foot mb-4">
        <nav class="tabs is-boxed is-pulled-right">
          <div class="container">
            <ul>
              <li> <a href="https://app.zune.money/signup" class="nav-link"><button
                    class="button is-success has-text-weight-bold">Try
                    Now!🚀</button></a></li>
            </ul>
          </div>
        </nav>
      </div>
    </section>

    <!-- FAQ -->
    <div class="faq-section mt-6 has-text-left">
      <h3 class="title is-5">Frequently Asked Questions</h3>
      <div class="content">
        <div class="faq-item" v-if="companyLastClose">
          <p class="question"><strong>What is {{ companyFundamentals.general.code }}’s current stock price?</strong></p>
          <p class="answer">
            As of today, {{ companyInfo.currentPriceCurrency }} {{ toLocaleNumber(companyLastClose) }}.
          </p>
        </div>
        <div class="faq-item">
          <p class="question"><strong>What is {{ companyFundamentals.general.code }}’s dividend yield?</strong></p>
          <p class="answer" v-if="companyFundamentals.highlights">
            As of today, {{ companyFundamentals.highlights.dividendYield ?
              toLocaleNumber(companyFundamentals.highlights.dividendYield * 100) + "%" : "Not available" }}.
          </p>
        </div>
        <div class="faq-item" v-if="companyFundamentals.general.address">
          <p class="question"><strong>Where is {{ companyInfo && companyInfo.name || companyFundamentals.general.name }} headquartered?</strong></p>
          <p class="answer">
            {{ companyFundamentals.general.address }}.
          </p>
        </div>
      </div>
    </div>
    <!-- / FAQ -->

    <!-- SIMILAR COMPANIES -->
    <div class="columns" v-if="this.companyInfo.peers && this.companyInfo.peers.length > 0">
      <div class="column">
        <div class="peers-section" v-if="companyInfo.peers && companyInfo.peers.length > 0">
          <h2 class="title">{{ companyFundamentals.general.code }} - Similar Companies</h2>
          <div class="columns is-multiline is-mobile">
            <div class="column is-12-mobile is-6-tablet is-4-desktop" v-for="peer in companyInfo.peers"
              :key="peer.symbol">
              <div class="card peer-card">
                <div class="card-content">
                  <router-link :to="{ name: 'Stock', params: { symbol: peer.symbol } }" class="peer-title">
                    {{ peer.companyName }} ({{ peer.symbol }})
                  </router-link>
                  <div class="peer-details">
                    <p class="exchange"><strong>Exchange:</strong> {{ peer.exchange }}</p>
                    <p class="ceo"><strong>CEO:</strong> {{ peer.ceo }}</p>
                    <p class="employees">
                      <strong>Employees:</strong> {{ toLocaleNumber(peer.employees) }}
                    </p>
                    <p class="location">
                      <strong>Location:</strong> {{ peer.city }}, {{ peer.country }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- / SIMILAR COMPANIES -->

    <!-- EXPLORE SECTORS -->
    <div class="explore-stocks-section">
      <h2 class="title">Explore Stocks by Sector</h2>
      <div class="sectors-container">
        <div v-for="(companies, sector) in sectors" :key="sector" class="sector-block">
          <h3 class="subtitle is-5 sector-title">{{ formatSectorName(sector) }}</h3>
          <div class="columns is-multiline is-mobile">
            <div class="column is-6-mobile is-4-tablet is-3-desktop" v-for="company in companies" :key="company.ticker">
              <router-link :to="{ name: 'Stock', params: { symbol: company.ticker } }" class="card stock-card">
                <div class="card-content">
                  <p class="stock-name">{{ company.name }}</p>
                  <p class="stock-ticker has-text-grey">{{ company.ticker }}</p>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- / EXPLORE SECTORS -->

    <!-- SIMILAR COMPANIES -->
    <div class="columns" v-if="this.sectors > 0">
      <div class="column">
        <h2 class="title">{{ companyFundamentals.general.code }} - Similar Companies</h2>
        <div class="columns">
          <div class="column" v-for="peer in this.companyInfo.peers" :key="peer.symbol">
            <ul>
              <li><router-link :to="{
                name: 'Stock',
                params: {
                  symbol: peer.symbol,
                },
              }" class="nav-link">{{ peer.companyName }} ({{ peer.symbol }})
                </router-link></li>
              <li>{{ peer.exchange }}</li>
              <li>CEO: {{ peer.ceo }}</li>
              <li>Employees: {{ toLocaleNumber(peer.employees) }}</li>
              <li>{{ peer.city }}</li>
              <li>{{ peer.country }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- / SIMILAR COMPANIES -->

    <!-- POSITION SUMMARY -->
    <div class="columns"
      v-if="false && companyFundamentals.general.codeActivity && companyFundamentals.general.codeActivity.length > 0">
      <div class="column is-one-third">
        <div class="level is-mobile">
          <div class="level-item has-text-centered">
            <div class="flex column">
              <p class="heading"></p>
              <p class="title is-size-5-mobile"></p>
              <div class="body">
                <div class="card events-card">
                  <header class="card-header">
                    <p class="card-header-title">Holding Insights</p>
                  </header>
                  <div class="card-table has-text-left">
                    <div class="content">
                      <table class="table is-fullwidth is-striped">
                        <tbody>
                          <tr>
                            <td>
                              <span>Size</span>
                            </td>
                            <td class="level-right">
                              {{
                                toLocaleNumber(
                                  companyFundamentals.general.codeActivity[
                                    companyFundamentals.general.codeActivity.length - 1
                                  ].quantity
                                )
                              }}
                            </td>
                          </tr>
                          <tr v-if="companyInfo.currentPrice">
                            <td>
                              <span>Value</span>
                            </td>
                            <td class="level-right">
                              {{
                                toLocaleNumber(
                                  companyFundamentals.general.codeActivity[
                                    companyFundamentals.general.codeActivity.length - 1
                                  ].quantity * companyInfo.currentPrice
                                )
                              }}
                            </td>
                          </tr>
                          <!-- <tr>
                            <td>
                              <span>Invested</span>
                            </td>
                            <td class="level-right">
                              {{ toLocaleNumber(companyInfo.stat.ttmEPS) }}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span>Profit</span>
                            </td>
                            <td class="level-right">
                              {{ companyInfo.stat.employees }}
                            </td>
                          </tr> -->
                          <!-- <tr>
                            <td>
                              <span>Dividends Earned (Estimate)</span>
                            </td>
                            <td class="level-right">
                              {{ companyInfo.website }}
                            </td>
                          </tr> -->
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <p class="footer">{{ companyInfo.currentPrice }}</p> -->
            </div>
          </div>
        </div>
      </div>
      <line-chart v-if="false" :chartData="this.symbolActivityChart.data" :options="this.symbolActivityChart.options"
        ref="symbolActivityChart" class="column is-two-thirds"></line-chart>
    </div>
    <!-- / POSITION SUMMARY -->
    <!-- <h5 class="title">Disclaimers</h2> -->
    <footer class="footer">
      <div class="container">
        <div class="columns is-multiline">
          <!-- Popular Stocks -->
          <div class="column is-12-mobile is-6-tablet is-3-desktop">
            <h3 class="footer-title">Popular Stocks</h3>
            <div class="footer-links">
              <router-link v-for="stock in popularStocks" :key="stock.ticker"
                :to="{ name: 'Stock', params: { symbol: stock.ticker } }">
                {{ stock.name }} ({{ stock.ticker }})
              </router-link>
            </div>
          </div>

          <!-- Sectors -->
          <div class="column is-12-mobile is-6-tablet is-3-desktop">
            <h3 class="footer-title">Explore Sectors</h3>
            <div class="footer-links">
              <router-link v-for="(sector, index) in Object.keys(sectors)" :key="index" :to="'/sector/' + sector">
                {{ formatSectorName(sector) }}
              </router-link>
            </div>
          </div>

          <!-- Navigation -->
          <div class="column is-12-mobile is-6-tablet is-3-desktop">
            <h3 class="footer-title">Quick Links</h3>
            <div class="footer-links">
              <router-link to="/">Home</router-link>
              <router-link to="/about">About ZuneMoney</router-link>
              <router-link to="/quotes">Stock Quotes</router-link>
              <router-link to="/dividends">Dividend Tracker</router-link>
              <router-link to="/contact">Contact</router-link>
            </div>
          </div>

          <!-- Legal & Attribution -->
          <div class="column is-12-mobile is-6-tablet is-3-desktop">
            <h3 class="footer-title">ZuneMoney</h3>
            <p class="footer-tagline">
              Track stock prices, dividends, and market trends with ZuneMoney.
            </p>
            <p class="disclaimer">
              ZuneMoney and its administrators do not assume any responsibility for the accuracy and completeness of the
              information provided.
            </p>
            <p class="attribution" v-if="companyFundamentals.general.webURL">
              <a href="https://clearbit.com" target="_blank" rel="noopener">Logos provided by Clearbit</a>
            </p>
            <p class="legal">
              © {{ currentYear }} ZuneMoney. All rights reserved.<br />
              <router-link to="/terms">Terms of Use</router-link> |
              <router-link to="/privacy">Privacy Policy</router-link>
            </p>
          </div>
        </div>
      </div>
    </footer>
  </section>
</template>

<script>
// @ is an alias to /src

import { Zune } from "@/services/zune.service";
import DividendsChart from "@/components/zuneCharts/BarChart";
import LineChart from "@/components/zuneCharts/LineChart";
// import StockFaq from "@/components/StockFaq";

export default {
  name: "Home",
  metaInfo() {
    let pageTitle = '';
    if (this.companyInfo.dividends && this.companyInfo.dividends.length > 0) {
      pageTitle = `${this.companyFundamentals.general.code}: ${this.companyInfo && this.companyInfo.name || this.companyFundamentals.general.name} Dividends - ZuneMoney`;
    } else {
      pageTitle = `${this.companyFundamentals.general.code}: ${this.companyInfo && this.companyInfo.name || this.companyFundamentals.general.name} Stock Price - ZuneMoney`;
    }

    return {
      title: pageTitle,
      meta: [
        { charset: 'utf-8' },
        {
          vmid: 'description',
          name: 'description',
          content: `Track ${this.companyFundamentals.general.code} stock price and ${this.companyInfo && this.companyInfo.name || this.companyFundamentals.general.name} dividends with ZuneMoney. Explore portfolio tools and analytics for smarter investments${this.companyFundamentals.general.sector ? ` in the ${this.companyFundamentals.general.sector.toLowerCase()} sector` : ''}.`
        },
        {
          vmid: 'keywords',
          name: 'keywords',
          content: `${this.companyFundamentals.general.code}, ${this.companyFundamentals.general.code} chart, ${this.companyFundamentals.general.code} price chart, ${this.companyFundamentals.general.code} dividends, ${this.companyInfo && this.companyInfo.name || this.companyFundamentals.general.name} dividends, ${this.companyInfo && this.companyInfo.name || this.companyFundamentals.general.name} chart, ${this.companyFundamentals.general.code} analyst estimates, ${this.companyFundamentals.general.code} earnings estimates, ${this.companyFundamentals.general.code} share estimates, ${this.companyFundamentals.general.code} analyst recommendations, ${this.companyFundamentals.general.code} ratings, ${this.companyInfo && this.companyInfo.name || this.companyFundamentals.general.name} analyst estimates, ${this.companyInfo && this.companyInfo.name || this.companyFundamentals.general.name} earnings estimates, ${this.companyInfo && this.companyInfo.name || this.companyFundamentals.general.name} analyst recommendations, ${this.companyInfo && this.companyInfo.name || this.companyFundamentals.general.name} analyst ratings.`
        }
      ]
    }
  },
  components: {
    DividendsChart,
    LineChart,
    // StockFaq
  },
  methods: {
    formatSectorName(sector) {
      return sector.charAt(0).toUpperCase() + sector.slice(1) + " Sector";
    },
    refreshCharts() {
      this.$refs.priceChart.update();

      if (this.companyInfo.dividends && this.companyInfo.dividends.length > 0) {
        this.$refs.dividendsChart.update();
      }

      if (
        this.companyFundamentals.general.codeActivity &&
        this.companyFundamentals.general.codeActivity.length > 0
      ) {
        this.$refs.symbolActivityChart.update();
      }
    },
    async period(option) {
      this.selectedPeriod = option;
      await this.getCompanyInfo();
      this.refreshCharts();
    },
    displayDividendsChart() {
      var payments = {
        labels: [],
        amounts: [],
      };

      for (let i = 0; i < this.companyDividends.length; i++) {
        // get dividend item
        let dividend =
          this.companyDividends[this.companyDividends.length - 1 - i];

        // set label & amount
        payments.labels[i] = dividend.paymentDate;
        payments.amounts[i] = dividend.value;
      }

      this.dividendsChart.data.labels = payments.labels;
      this.dividendsChart.data.datasets[0].data = payments.amounts;
    },
    displayPriceHistoryChart() {
      var priceSeries = {
        labels: [],
        amounts: [],
      };

      if (this.companyInfo.priceHistory) {
        for (let i = 0; i < this.companyInfo.priceHistory.length; i++) {
          // get price item
          let price = this.companyInfo.priceHistory[i];

          // set label & amount
          priceSeries.labels[i] = new Date(price.date).toLocaleDateString();
          priceSeries.amounts[i] = price.close;
        }
      }

      this.priceChart.data.labels = priceSeries.labels;
      this.priceChart.data.datasets[0].data = priceSeries.amounts;
    },
    async getCompanyInfo() {
      const [companyLastClose, companyFundamentals, companyDividends, response] = await Promise.all([
        Zune.publicCompanyLastClose(this.$route.params.symbol),
        Zune.publicCompanyFundamentals(this.$route.params.symbol),
        Zune.publicCompanyDividends(this.$route.params.symbol),
        Zune.publicCompany(this.$route.params.symbol, this.selectedPeriod)
      ]);

      this.companyInfo = response.data;
      this.companyLastClose = companyLastClose.data;
      this.companyFundamentals = companyFundamentals.data;
      this.companyDividends = companyDividends.data;
      this.companyDividends.sort((a, b) => new Date(b.paymentDate) - new Date(a.paymentDate));
      this.displayDividendsChart();
      // this.displayPriceHistoryChart();

      // document.title = `${this.companyFundamentals.general.code}: ${this.companyFundamentals.general.name}. Dividend Tracking and Stock Price`;
    },
  },
  async beforeRouteUpdate(to, from, next) {
    next();
    await this.getCompanyInfo();
  },
  updated() {
    //this.refreshCharts();
  },
  async mounted() {
    await this.getCompanyInfo();
  },
  data() {
    return {
      companyLastClose: undefined,
      companyFundamentals: {
        general: {},
        highlights: {}
      },
      companyDividends: [],
      sectors: {
        tech: [
          { name: "Apple", ticker: "AAPL" },
          { name: "Microsoft", ticker: "MSFT" },
          { name: "Amazon", ticker: "AMZN" },
          { name: "Google", ticker: "GOOGL" },
          { name: "NVIDIA", ticker: "NVDA" },
          { name: "Meta", ticker: "META" },
          { name: "Tesla", ticker: "TSLA" },
          { name: "Intel", ticker: "INTC" },
          { name: "Cisco", ticker: "CSCO" },
          { name: "Adobe", ticker: "ADBE" }
        ],
        energy: [
          { name: "Engie", ticker: "ENGI.PA" },
          { name: "NextEra Energy", ticker: "NEE" },
          { name: "ExxonMobil", ticker: "XOM" },
          { name: "Chevron", ticker: "CVX" },
          { name: "Shell", ticker: "SHEL" },
          { name: "BP", ticker: "BP" },
          { name: "TotalEnergies", ticker: "TTE" },
          { name: "ConocoPhillips", ticker: "COP" },
          { name: "Duke Energy", ticker: "DUK" },
          { name: "Enbridge", ticker: "ENB" }
        ],
        finance: [
          { name: "JPMorgan Chase", ticker: "JPM" },
          { name: "Visa", ticker: "V" },
          { name: "Mastercard", ticker: "MA" },
          { name: "Bank of America", ticker: "BAC" },
          { name: "Wells Fargo", ticker: "WFC" },
          { name: "Goldman Sachs", ticker: "GS" },
          { name: "Morgan Stanley", ticker: "MS" },
          { name: "Citigroup", ticker: "C" },
          { name: "BlackRock", ticker: "BLK" },
          { name: "PayPal", ticker: "PYPL" }
        ],
        healthcare: [
          { name: "Pfizer", ticker: "PFE" },
          { name: "Johnson & Johnson", ticker: "JNJ" },
          { name: "Moderna", ticker: "MRNA" },
          { name: "Gilead Sciences", ticker: "GILD" },
          { name: "Abbott Labs", ticker: "ABT" },
          { name: "Merck", ticker: "MRK" },
          { name: "Amgen", ticker: "AMGN" },
          { name: "Eli Lilly", ticker: "LLY" },
          { name: "Bristol Myers Squibb", ticker: "BMY" },
          { name: "CVS Health", ticker: "CVS" }
        ],
        consumer: [
          { name: "Walmart", ticker: "WMT" },
          { name: "Coca-Cola", ticker: "KO" },
          { name: "PepsiCo", ticker: "PEP" },
          { name: "Procter & Gamble", ticker: "PG" },
          { name: "Nike", ticker: "NKE" },
          { name: "Costco", ticker: "COST" },
          { name: "Home Depot", ticker: "HD" },
          { name: "Target", ticker: "TGT" },
          { name: "McDonald's", ticker: "MCD" },
          { name: "Starbucks", ticker: "SBUX" }
        ],
        industrials: [
          { name: "Boeing", ticker: "BA" },
          { name: "Caterpillar", ticker: "CAT" },
          { name: "General Electric", ticker: "GE" },
          { name: "Honeywell", ticker: "HON" },
          { name: "3M", ticker: "MMM" },
          { name: "Lockheed Martin", ticker: "LMT" },
          { name: "UPS", ticker: "UPS" },
          { name: "FedEx", ticker: "FDX" },
          { name: "Deere & Company", ticker: "DE" },
          { name: "Raytheon", ticker: "RTX" }
        ],
        telecom: [
          { name: "AT&T", ticker: "T" },
          { name: "Verizon", ticker: "VZ" },
          { name: "T-Mobile", ticker: "TMUS" },
          { name: "Comcast", ticker: "CMCSA" },
          { name: "Charter Communications", ticker: "CHTR" },
          { name: "Vodafone", ticker: "VOD" },
          { name: "Orange", ticker: "ORAN" },
          { name: "Deutsche Telekom", ticker: "DTEGY" },
          { name: "SoftBank", ticker: "SFTBY" },
          { name: "Dish Network", ticker: "DISH" }
        ],
        materials: [
          { name: "Dow Inc.", ticker: "DOW" },
          { name: "DuPont", ticker: "DD" },
          { name: "Freeport-McMoRan", ticker: "FCX" },
          { name: "Newmont", ticker: "NEM" },
          { name: "Barrick Gold", ticker: "GOLD" },
          { name: "BHP Group", ticker: "BHP" },
          { name: "Rio Tinto", ticker: "RIO" },
          { name: "Vale", ticker: "VALE" },
          { name: "Sherwin-Williams", ticker: "SHW" },
          { name: "Ecolab", ticker: "ECL" }
        ],
        utilities: [
          { name: "Southern Company", ticker: "SO" },
          { name: "Dominion Energy", ticker: "D" },
          { name: "American Electric Power", ticker: "AEP" },
          { name: "PG&E", ticker: "PCG" },
          { name: "Exelon", ticker: "EXC" },
          { name: "Xcel Energy", ticker: "XEL" },
          { name: "PPL Corporation", ticker: "PPL" },
          { name: "Edison International", ticker: "EIX" },
          { name: "FirstEnergy", ticker: "FE" },
          { name: "Sempra Energy", ticker: "SRE" }
        ],
        realEstate: [
          { name: "American Tower", ticker: "AMT" },
          { name: "Prologis", ticker: "PLD" },
          { name: "Crown Castle", ticker: "CCI" },
          { name: "Equinix", ticker: "EQIX" },
          { name: "Simon Property", ticker: "SPG" },
          { name: "Public Storage", ticker: "PSA" },
          { name: "Welltower", ticker: "WELL" },
          { name: "AvalonBay", ticker: "AVB" },
          { name: "Equity Residential", ticker: "EQR" },
          { name: "Digital Realty", ticker: "DLR" }
        ]
      },
      popularStocks: [
        { name: "Apple", ticker: "AAPL" },
        { name: "Microsoft", ticker: "MSFT" },
        { name: "Tesla", ticker: "TSLA" },
        { name: "Amazon", ticker: "AMZN" },
        { name: "Google", ticker: "GOOGL" },
      ],
      currentYear: new Date().getFullYear(),
      companyInfo: {
        stat: {},
        extendedStats: {},
        faq: {},
      },
      symbolActivityChart: {
        data: {
          labels: [],
          datasets: [
            {
              label: "Owned Shares",
              backgroundColor: "#f87979",
              fill: false,
              borderColor: "#f87979",
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              // review
              // beginAtZero: true,
              // suggestedMax: max + (max * 0.2)
            },
          },
        },
      },
      priceChart: {
        data: {
          labels: [],
          datasets: [
            {
              label: "Price History",
              backgroundColor: "#f87979",
              fill: false,
              borderColor: "#f87979",
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              // review
              // beginAtZero: true,
              // suggestedMax: max + (max * 0.2)
            },
          },
        },
      },
      dividendsChart: {
        data: {
          labels: [],
          datasets: [
            {
              label: "Dividend History",
              backgroundColor: "#f87979",
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  min: 0,
                },
              },
            ],
          },
        },
      },
      // page settings
      selectedPeriod: "month",
    };
  },
};
</script>
<style scoped>
/* DIVIDENDS TABLE */
.dividends-section {
  padding: 1rem;
  overflow-x: auto;
  /* Ensures table scrolls horizontally on small screens if needed */
}

.table {
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

th {
  font-weight: 600;
  color: #363636;
  white-space: nowrap;
}

td {
  vertical-align: middle;
}

.amount {
  font-weight: 500;
  color: #3273dc;
  /* Bulma primary color */
}

/* Mobile Card Styles */
.dividend-cards {
  display: none;
  /* Hidden by default, shown on mobile */
}

.dividend-card {
  margin-bottom: 1rem;
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.card-content {
  padding: 1rem;
}

.payable-date {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.card-content p {
  font-size: 0.95rem;
  color: #4a4a4a;
  margin-bottom: 0.5rem;
}

.card-content strong {
  color: #363636;
}

/* Responsive Adjustments */
@media screen and (max-width: 768px) {
  .table {
    display: none;
    /* Hide table on mobile */
  }

  .dividend-cards {
    display: block;
    /* Show cards on mobile */
  }
}

/* / DIVIDENDS TABLE */
/* PEERS DESIGN */
.peers-section {
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 8px;
  margin-top: 2rem;
}

.peer-card {
  height: 100%;
  transition: transform 0.2s, box-shadow 0.2s;
}

.peer-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.card-content {
  padding: 1.25rem;
}

.peer-title {
  display: block;
  font-size: 1.1rem;
  font-weight: 600;
  color: #3273dc;
  /* Bulma primary color */
  text-decoration: none;
  margin-bottom: 0.75rem;
}

.peer-title:hover {
  text-decoration: underline;
}

.peer-details p {
  font-size: 0.95rem;
  color: #4a4a4a;
  margin-bottom: 0.5rem;
}

.peer-details strong {
  color: #363636;
}

.exchange,
.ceo,
.employees,
.location {
  line-height: 1.4;
}

/* / PEERS DESIGN */
.explore-stocks-section {
  padding: 2rem;
  background-color: #f5f5f5;
  border-radius: 8px;
  margin-top: 2rem;
}

.sector-block {
  margin-bottom: 2rem;
}

.sector-title {
  font-weight: 600;
  margin-bottom: 1rem;
  color: #363636;
}

.stock-card {
  display: block;
  text-decoration: none;
  transition: transform 0.2s;
}

.stock-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.stock-name {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.stock-ticker {
  font-size: 0.9rem;
}

/* FAQ */
.faq-section {
  padding: 0;
}

.content {
  max-width: 800px;
  margin: 0 auto;
}

.faq-item {
  margin-bottom: 1.5rem;
}

.question {
  font-size: 1.1rem;
  font-weight: 600;
  color: #363636;
  margin-bottom: 0.5rem;
}

.answer {
  font-size: 1rem;
  color: #4a4a4a;
}

/* Left align on desktop (tablet and up) */
@media screen and (min-width: 769px) {
  .content {
    margin: 0;
    /* Remove centering */
    text-align: left;
    /* Align text left */
  }
}

/* / FAQ */
/* FOOTER */
.footer {
  background-color: #2c3e50;
  /* Dark blue-gray */
  padding: 3rem 1.5rem;
  color: #ecf0f1;
}

.footer-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 1rem;
}

.footer-links {
  display: flex;
  flex-direction: column;
}

.footer-links a {
  color: #bdc3c7;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
}

.footer-links a:hover {
  color: #ffffff;
  text-decoration: underline;
}

.footer-tagline {
  font-size: 0.9rem;
  color: #95a5a6;
  margin-bottom: 1rem;
}

.disclaimer {
  font-size: 0.85rem;
  color: #7f8c8d;
  margin-bottom: 1rem;
}

.attribution a {
  color: #bdc3c7;
  font-size: 0.85rem;
}

.attribution a:hover {
  color: #ffffff;
}

.legal {
  font-size: 0.85rem;
  color: #7f8c8d;
}

.legal a {
  color: #bdc3c7;
}

.legal a:hover {
  color: #ffffff;
}

/* / FOOTER */
</style>
